//this code is used only for sestion 1 to section 8.
import React, { useState, useEffect, useRef, useContext } from 'react'
import { fetchAdminApi, fetchApi } from '../../services/api'
import { Row, Form, Col, Button, Space, Input, Spin, message, Table, Tooltip, Popconfirm, Select, Alert } from 'antd';
// import { luscherColorTest, template0, template1, template10, template11, template12, template14, template15, template16, template17, template2, template23, template4, template6, template9 } from '../../globalFunctions/GlobalFunctions';
import { template0, template1, template10, template11, template13, template2, template3, template4, template5, template6, template7, template8, template9, template15, template18, template19, template20, luscherColorTest, template24, template26, template12, templateYouTube, templateVideoStreaming } from './Templates';
import constants from '../../constants/constants';
import { COOKIE, getCookie } from '../../services/cookie';
import { DOB, notesModel, popupMessage, template23, uploadFile } from '../../globalFunctions/GlobalFunctions';
import { DeleteOutlined, SaveOutlined, CheckOutlined, CheckCircleTwoTone, EditOutlined, UserOutlined, InfoCircleOutlined, TrophyOutlined, CloseOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from "react-router";

//Editable cells and rows Table code starts here
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};
const EditableCell = ({
	editable,
	children,
	dataIndex,
	record,
	handleSaveTable,
	tableName,
	handleSaveTable2,
	handleAdd,
	disable,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const form = useContext(EditableContext);
	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);
	const toggleEdit = (param = null) => {
		setEditing(!editing);
		form.setFieldsValue({ [dataIndex]: record[dataIndex], });
	};
	//function to save and edit the cells
	const save = async (e) => {
		try {
			const values = await form.validateFields();
			toggleEdit();
			if (tableName === "Mylist" || tableName === "Mylist2" || tableName === "tableCode" || tableName === "AngerSheetTable" || tableName === "FearTable") {
				if (e) {
					handleAdd("EDIT", {
						...record,
						...values,
					});
				}
				if (e.code === "Enter") {
					handleAdd("ADD", {
						...record,
						...values,
					}, tableName);
				}
			} else {
				if (e) {
					handleAdd("EDIT", {
						...record,
						...values,
					});
				}
			}
		} catch (errInfo) {
			console.log('Save failed:', errInfo);
		}
	};
	let childNode = children;
	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{ margin: 0, }}
				name={dataIndex}
			>
				<Input readOnly={disable} ref={inputRef} onPressEnter={save} onBlur={save} />
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{ paddingRight: 24, }}
				onClick={() => toggleEdit("text")}
			>
				{children}
			</div>
		);
	}
	return <td {...restProps}>{childNode}</td>;
};
const WorkbookSections = (props) => {
	const [form] = Form.useForm();
	const history = useHistory();
	const [loader, setLoader] = useState(false);
	//signature pad ref
	const sigPad = useRef(null);
	const adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
	const [esignValue, setEsignValue] = useState("");
	//Instrustion modal
	const [instructionModalOpen, setInstructionModalOpen] = useState(false);
	const [showInstnPopup, setShowInstnPopup] = useState(true);
	const [sectionData, setSectionData] = useState([]);
	const loginType = getCookie(COOKIE.LoginType);
	const [clientId, setClientId] = useState(props?.location?.state?.isAdmin || adminClientValuefromSession?.isAdmin ? props?.location?.state?.clientId || adminClientValuefromSession?.clientId : getCookie(COOKIE.ClientId));
	const [isResume, setIsResume] = useState({});
	const [counter, setCounter] = useState(() => {
		const persistedValue = sessionStorage.getItem('count');
		return persistedValue !== null ? JSON.parse(persistedValue) : 1;
	});
	const [sectionId, setSectionId] = useState(props?.location?.state?.section_id ? props?.location?.state?.section_id : sessionStorage.getItem('section_id'));
	const [enableTill, setEnableTill] = useState("");
	const [sectionLength, setSectionLength] = useState("");
	const [lastDiscussedPartId, setlastDiscussedPartId] = useState("");
	const [clientData, setClientData] = useState([]);
	const [reload, setReload] = useState(false);
	const [goToPageData, setGoToPageData] = useState([]);
	const [secZeroSubTitle, setSecZeroSubTitle] = useState([]);
	//notes
	const [notesVisible, setnotesVisible] = useState(false);
	const [noteValue, setNoteValue] = useState(null);
	const [noteTypeValue, setNoteTypeValue] = useState("0");
	const [isNoteAnswered, setIsNoteAnswered] = useState(false);
	const [sessionChecked, setSessionChecked] = useState(false);
	const current = new Date();
	const [noteDate, setNoteDate] = useState({
		date: current.getDate(),
		month: String(current.getMonth() + 1),
		year: current.getFullYear()
	});
	const newUser = props?.location?.state?.new_user || adminClientValuefromSession?.new_user;
	//upload file
	const [fileList, setFileList] = useState([]);
	//table with checkbox
	const [tableCheckedValues, setTableCheckedValues] = useState([]);
	//editable table
	const [tableOneDataSource, setTableOneDataSource] = useState([]);
	const tableComponents = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};
	const [displayGamblingValidateMessage, setDisplayGamblingValidateMessage] = useState(false);
	const tableOneColumns = [
		{
			title: 'How can I respond better?',
			dataIndex: 'futureAction',
			width: '50%',
			editable: false,
			render: (futureAction) => {
				return Array.isArray(futureAction) ? futureAction.join(", ") : futureAction;
			}
		},
		/* {
			title: 'Action',
			dataIndex: 'operation',
			align: "center",
			width: '10%',
			render: (_, record) => {
				if (tableOneDataSource?.length > 1) {
					return <Tooltip title={constants.Delete}><Popconfirm disabled={adminClientValuefromSession?.isAdmin} title="Sure to Delete?" onConfirm={() => handleDelete(record.key,)}>
						<Button type="link" danger icon={<DeleteOutlined />} />
					</Popconfirm></Tooltip>;
				}
			}
		},*/
	];
	//lusher color test
	const colors = [
		"rgb(255, 51, 0)", /*Orange-Red color*/
		"rgb(255, 204, 0)", /*Bright Yellow color*/
		"rgb(0, 84, 71)", /*Blue-Green color*/
		"rgb(0, 0, 85)", /*Dark Blue color*/
		"rgb(153, 51, 0)", /*Brown color*/
		"rgb(153, 153, 153)", /*Grey color*/
		"rgb(0, 0, 0)", /*Black color*/
		"rgb(153, 0, 102)" /*Violet color*/
	];
	const [boxes, setBoxes] = useState([
		{
			id: 1,
			color: ""
		},
		{
			id: 2,
			color: ""
		},
		{
			id: 3,
			color: ""
		},
		{
			id: 4,
			color: ""
		},
		{
			id: 5,
			color: ""
		},
		{
			id: 6,
			color: ""
		},
		{
			id: 7,
			color: ""
		},
		{
			id: 8,
			color: ""
		},
	]);
	const [colorSequence, setColorSequence] = useState([]);
	// Anger sheet tables
	const [newSec4Option, setNewSec4Option] = useState(constants.Sec4FutureOptions);
	const [newSec5Option, setNewSec5Option] = useState(constants.Sec5FutureOptions);
	const [newOption, setNewOption] = useState('');
	const FutureInputRef = useRef(null);

	const handleBoxClick = (id, color, item_id) => {
		const updatedBoxes = boxes.map((box) => {
			if (box.id === id) {
				return {
					...box,
					visible: false
				};
			} else {
				return box;
			}
		});
		setBoxes(updatedBoxes);
		let colorName = null;
		if (color === "rgb(255, 51, 0)") {
			colorName = "Orange-Red";
		} else if (color === "rgb(255, 204, 0)") {
			colorName = "Bright Yellow";
		} else if (color === "rgb(0, 84, 71)") {
			colorName = "Blue-Green";
		} else if (color === "rgb(0, 0, 85)") {
			colorName = "Dark Blue";
		} else if (color === "rgb(153, 51, 0)") {
			colorName = "Brown";
		} else if (color === "rgb(153, 153, 153)") {
			colorName = "Grey";
		} else if (color === "rgb(0, 0, 0)") {
			colorName = "Black";
		} else if (color === "rgb(153, 0, 102)") {
			colorName = "Violet";
		}
		if (colorSequence.length > 0) {
			let found = false;
			for (let index = 0; index < colorSequence.length; index++) {
				const element = colorSequence[index];
				if (element !== colorName) {
					colorSequence.push(colorName);
					found = true;
				}
				if (found) {
					break;
				}
			}
		} else {
			colorSequence.push(colorName);
		}
		let check = allVisibleFalse(updatedBoxes);
		if (check) {
			if (loginType === "0") {
				let payload = {
					item_id: item_id,
					json_data: colorSequence,
					section_id: props?.location?.pathname === "/refresher-workbook" ? "100" : sectionId,
				}
				clientData.push(payload);
				handleValidate("LusherColor");
			}
			setColorSequence([]);
		}
	};

	//upload file change
	const handleFileChange = ({ fileList }) => {
		setFileList(fileList);
	};

	//handle upload replace file.
	const handleRemove = (file) => {
		// remove the file from the fileList
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
	};

	//hadle function before upload file.
	function beforeUpload(file) {
		const isJpgOrPng =
			file.name.includes(".pdf") ||
			file.name.includes(".jpg") ||
			file.name.includes(".png") ||
			file.name.includes(".jpeg");
		if (!isJpgOrPng) {
			message.error("You can only upload PDF/JPEG/PNG file!");
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error("Max file size accepted 2MB!");
		}
		return isLt2M;
	};

	// Function to check if all objects have visible set to false for lushur color test.
	function allVisibleFalse(data) {
		for (let i = 0; i < data.length; i++) {
			if (data[i].visible !== false) {
				return false;
			}
		}
		return true;
	};

	//func to get random color for lushur color test.
	const updateBoxColors = () => {
		//genereting different colors
		const shuffledColors = colors.sort(() => 0.5 - Math.random());
		const initialBoxes = shuffledColors.map((color, index) => {
			return {
				id: index + 1,
				color: color,
				visible: true
			};
		});
		setBoxes(initialBoxes);
	};

	//To reset lushur color test.
	const resetLusherColor = () => {
		let payload = { client_id: adminClientValuefromSession?.clientId };
		fetchAdminApi(`/reset-luscher-color`, "post", payload).then((res) => {
			if (res && res.code === 200) {
				fetchWorkbookSections(sectionId, counter);
				message.success(res.message);
			} else {
				message.error(res.message);
			}
		});
	};

	//download lusher color result
	const downloadResult = () => {
		let documentValue = {
			client_id: adminClientValuefromSession?.clientId, /*"client_id from admin login"*/
			file_type: "result-pdf",
		};
		sessionStorage.setItem("documentValue", JSON.stringify(documentValue));
		window.open(constants.BaseUrl + `/view-document`);
		// window.open(constants.ApiUrl + `/download-test-pdf/${adminClientValuefromSession?.clientId}`,"_blank");
	};

	//function to delete row from a editable table
	const handleDelete = (key) => {
		console.log("Key to delete:", key);

		// Find the record to be deleted
		const recordToDelete = tableOneDataSource.find((item) => item.key === key);
		if (!recordToDelete) {
			console.log("Record not found for key:", key);
			return; // If no record found, do nothing
		}

		console.log("Record to delete:", recordToDelete);

		const { flaws } = recordToDelete;

		// Filter out the record to be deleted
		const updatedData = tableOneDataSource.filter((item) => item.key !== key);

		console.log("Updated data after deletion:", updatedData);

		// Find all records with the same name
		const recordsWithSameName = updatedData.filter((item) => item.flaws === flaws);

		console.log("Records with the same name:", recordsWithSameName);

		if (recordsWithSameName.length > 0) {
			// Find the record with the lowest key among the duplicates
			const recordWithLowestKey = recordsWithSameName.reduce((lowest, item) => {
				return item.key < lowest.key ? item : lowest;
			}, recordsWithSameName[0]);

			console.log("Record with the lowest key:", recordWithLowestKey);

			// Update its `isDuplicate` property to false
			recordWithLowestKey.isDuplicate = false;
		} else {
			console.log("No records with the same name found.");
		}

		// Update the state with the modified data
		setTableOneDataSource([...updatedData]);
		console.log("Final data after processing:", updatedData);
	};

	// function for dropdown table
	const handleChange = (e, record) => {
		const updatedDataSource = tableOneDataSource.map((element) => {
			if (element.key === record.key) {
				return {
					...element,
					affected: e
				};
			}
			return element;
		});
		setTableOneDataSource(updatedDataSource);
	};

	// function for Anger sheet table 3
	const handleFearChange = (e, record) => {
		const array = typeof e;
		const result = array !== "string" ? e?.join(", ") : e;
		const updatedDataSource = tableOneDataSource.map((element) => {
			if (element.key === record.key) {
				return {
					...element,
					fearAction: result
				};
			}
			return element;
		});
		setTableOneDataSource(updatedDataSource, "fearAction");
	};

	const handleFearInputChange = (e, record) => {
		const updatedDataSource = tableOneDataSource.map((element) => {
			if (element.key === record.key) {
				return {
					...element,
					fearCause: e.target.value
				};
			}
			return element;
		});
		setTableOneDataSource(updatedDataSource);
		console.log(updatedDataSource, "fearCause");
	};


	const handleCheckboxChange = (key, checked) => {

		// Update the specific record in the data source
		const updatedDataSource = tableOneDataSource.map((item) => {
			if (item.key === key) {
				return { ...item, isChecked: checked };
			}
			return item;
		});

		setTableOneDataSource(updatedDataSource);
		console.log(updatedDataSource);
	};

	// function for dropdown table - Anger Sheet
	const handleFutureChangeAndMyreaction = (e, record,type) => {
		const updatedDataSource = tableOneDataSource.map((element) => {
			if (element.key === record.key) {
				return {
					...element,
					[type]: e
				};
			}
			return element;
		});
		setTableOneDataSource(updatedDataSource);
	};

	// Update new option value
	const handleNewOptionChange = (e) => {
		setNewOption(e.target.value);
	};
	const handleAddNewOption = (condition) => {
		if (!newOption) return;
		condition ? setNewSec4Option([...newSec4Option, newOption]) : setNewSec5Option([...newSec5Option, newOption]);
		setNewOption('');
		setTimeout(() => FutureInputRef.current?.focus(), 0);
	};

	//function to add a row on specific position.
	const handleAddOnSpecificIndex = (index, tableType) => {
		let newEntry = {
			...tableOneDataSource[index - 1],
			key: tableOneDataSource.length + 1,
			isDuplicate: true
		};

		if (tableType === "tableCode") {
			newEntry = {
				...newEntry,
				name: "",
				problem: "",
				affected: ""
			};
		} else if (tableType === "AngerSheetTable") {
			newEntry = {
				...newEntry,
				problem: "",
				affected: "",
				reaction: "",
				futureAction: ""
			};
		} else if (tableType === "FearTable") {
			newEntry = {
				...newEntry,
				fearCause: ``,
				affected: ``,
				fearAction: ``,
				futureAction: ``,
			};
		} else if (tableType === "Anger Sheet table 2"){
			newEntry = {
				...newEntry,
				is_add: true,
				fearCause: ``,
				fearAction: ``,
				futureAction: ``,
				myreaction:``,
				affected:``
			};
		}

		const updatedDataSource = [
			...tableOneDataSource.slice(0, index),
			newEntry,
			...tableOneDataSource.slice(index)
		];

		setTableOneDataSource(updatedDataSource);
	};

	// function to save the table data (particular row)
	const handleActionSave = (record, value) => {
		const index = tableOneDataSource.findIndex((item) => record.key === item.key);
		let userPayload = [];
		let payload = {
			item_id: value?.item_id,
			json_data: tableOneDataSource,
			section_id: sectionId
		}
		userPayload.push(payload);
		if (index > -1) {
			let previousData = value?.client_ans !== null ? value?.client_ans[index] : [] || value?.fears[index];
			let updatedData = tableOneDataSource[index];
			console.log(previousData);
			console.log(updatedData);
			if (JSON.stringify(previousData) !== JSON.stringify(updatedData)) {
				addAnswers(userPayload, "show success message");
			} else {
				message.error("No data to Save")
			}
		}
	};
	//function to add the row to the editable table
	const handleAdd = (param = null, row, table = null, addRow = null) => {
		let dataSource = [...tableOneDataSource];
		if (param === "ADD") {
			let index = dataSource.findIndex((item) => item.key === row.key);
			if (addRow !== null) {
				dataSource.push(row);
				setTableOneDataSource(dataSource);
			}
			if (index > -1) {
				dataSource[index] = row;
				if (dataSource[dataSource.length - 1].name !== "") {
					if (table === "AngerSheetTable") {
						dataSource.push({
							key: tableOneDataSource.reduce((previous, current) => {
								return current.key > previous.key ? current : previous;
							}).key + 1,
							name: ``,
							problem: ``,
							affected: ``,
							futureAction: ``,
							reaction: ``,
							is_pushed: 0,
							table: "AngerSheetTable",
						});
						setTableOneDataSource(dataSource);
					} else if (table === "FearTable") {
						dataSource.push({
							key: tableOneDataSource.reduce((previous, current) => {
								return current.key > previous.key ? current : previous;
							}).key + 1,
							name: ``,
							fearAction: ``,
							fearCause: ``,
							affected: ``,
							futureAction: ``,
							is_pushed: 0,
							table: "FearTable",
						});
						setTableOneDataSource(dataSource);
					} else if (table === "tableCode") {
						dataSource.push({
							key: tableOneDataSource.reduce((previous, current) => {
								return current.key > previous.key ? current : previous;
							}).key + 1,
							name: ``,
							flaws: ``,
							problem: ``,
							affected: ``,
							is_pushed: 0,
							table: "tableCode",
						});
						setTableOneDataSource(dataSource);
					}
				}
			}
		} else if (param === "EDIT") {
			let index = dataSource.findIndex((item) => item.key === row.key);
			if (dataSource[index].name !== row.name || dataSource[index].problem !== row.problem || dataSource[index].affected !== row.affected || dataSource[index].futureAction !== row.futureAction || dataSource[index].reaction !== row.reaction || dataSource[index].fearCause !== row.fearCause || dataSource[index].fearAction !== row.fearAction) {
				dataSource[index] = row;
				setTableOneDataSource(dataSource);
			}
		}
	};

	useEffect(() => {
		fetchWorkbookSections(sectionId, counter);
	}, [counter]);

	useEffect(() => {
	}, [reload]);

	useEffect(() => {
		updateBoxColors();
		if (props?.location?.state?.section_id) {
			sessionStorage.setItem('section_id', props?.location?.state?.section_id);
		}
	}, []);

	//handle table data entered by client.
	const handleEditableTableClientData = () => {
		const findIndex = sectionData?.findIndex((val) => val.template_id === "15");
		if (findIndex > -1) {
			let updatedData = JSON.stringify(tableOneDataSource);
			let previousData = JSON.stringify(sectionData[findIndex].client_ans);
			if (updatedData !== previousData && findIndex > -1) {
				let payload = {
					item_id: sectionData[findIndex].item_id,
					section_id: sectionId,
					json_data: tableOneDataSource
				};
				if (sectionData[findIndex].content_left === "Anger Sheet table 3" || sectionData[findIndex].content_left === "Anger Sheet table 4" || sectionData[findIndex].content_left === "Anger Sheet table 5" || sectionData[findIndex].content_left === "Anger Sheet table 6") {
					payload.option_data = "1"
				}
				if (sectionData[findIndex]?.is_discussed === 1) {
					payload.is_changed = 1;
				};
				clientData.push(payload);
			}
		}
	};

	const handleCheckboxTableClientData = () => {
		let temp15Index = sectionData?.findIndex((val) => val?.template_id === "18");
		if (temp15Index > -1) {
			let previousData = sectionData[temp15Index].client_ans
			let updatedData = tableCheckedValues;
			if (JSON.stringify(previousData) !== JSON.stringify(updatedData)) {
				let userPayload = {
					item_id: sectionData[temp15Index].item_id,
					json_data: updatedData,
					section_id: sectionId
				}
				if (sectionData[temp15Index]?.is_discussed === 1) {
					userPayload.is_changed = 1;
				}
				clientData.push(userPayload);
			}
		}
	};

	//fetch workbook data
	const fetchWorkbookSections = (sectionId, part) => {
		setLoader(true);
		let payload = {
			section: sectionId,
			part: part
		}
		fetchApi(`/get-sections/${clientId}`, "post", payload).then((res) => {
			if (res?.code === 200) {
				setLoader(false);
				handleInitialTableData(res?.data[part]);
				setIsResume(res?.is_resume);
				setSectionData(res?.data[part]);
				setGoToPageData(res?.section_page_title);
				setEnableTill(parseInt(res?.enabled_page));
				setSectionLength(parseInt(res?.section_length));
				setlastDiscussedPartId(res?.last_discussed_part_id);
				setSecZeroSubTitle(res?.sub_titles);
				let checkboxTableIndex = res?.data[part]?.findIndex((val) => val?.template_id === "18");
				setTableCheckedValues(res?.data[part][checkboxTableIndex]?.client_ans);
				let findIndex = res?.data[part]?.findIndex((val) => val?.section_res);
				if (findIndex > -1) {
					//setting form fields for summary.
					setFormItemValues(res?.data?.[part]?.[findIndex]?.section_res, res?.data?.[part]?.[findIndex]?.section_id);
				} else {
					setFormItemValues(res?.data[part]);
				}
				//instruction modal for loginType zero.
				if (counter === 1 && loginType === "0" && res?.show_section_popup && showInstnPopup) {
					// setIsCompleteSectionPopup(true);
					setShowInstnPopup(false);
					showInstructionModal();
				}
				// else{
				//   setIsCompleteSectionPopup(false);
				// }
			} else {
				setSecZeroSubTitle([]);
				setEnableTill("");
				setSectionLength("");
				setSectionData([]);
				setlastDiscussedPartId("");
				setGoToPageData([]);
				setIsResume({});
				setLoader(false);
			}
		})
	};

	//setting initial table data for template 15.
	const handleInitialTableData = (data) => {
		const findIndex = data?.findIndex((val) => val?.template_id === "15");
		if (findIndex > -1) {
			if (data[findIndex].client_ans === null) {
				if (data[findIndex].content_left === "Anger Sheet table") {
					let datasource = [];
					if (Array.isArray(data[findIndex].anger) && data[findIndex].anger.length > 0) {
						datasource = data[findIndex].anger.map((angerItem, index) => ({
							key: index + 1,
							name: angerItem.name,
							problem: ``,
							affected: ``,
							reaction: ``,
							futureAction: ``,
							table: "AngerSheetTable",
							is_pushed: 0,
							item_id:angerItem.item_id
						}));
					}
					setTableOneDataSource(datasource);
				} else if (data[findIndex].content_left === "Anger Sheet table 2") {
					let datasource = [];
					if (Array.isArray(data[findIndex].fears) && data[findIndex].fears.length > 0) {
						datasource = data[findIndex].fears.map((fear, index) => ({
							key: index + 1,
							name: fear.name || ``,
							fearCause: fear.fearCause || ``,
							affected: ``,
							fearAction: fear.fearAction || ``,
							futureAction: fear.futureAction || ``,
							table: "FearTable",
							is_pushed: 0,
							is_add: fear?.is_add,
							is_ticked: fear?.is_ticked,
							item_id:fear.item_id,
							myreaction:fear.myreaction,
						}));
					}
					setTableOneDataSource(datasource);
				} else if (data[findIndex].content_left === "table code") {
					let datasource = [];
					if (Array.isArray(data[findIndex].flaws) && data[findIndex].flaws.length > 0) {
						datasource = data[findIndex].flaws.map((flaw, index) => ({
							key: index + 1,
							flaws: flaw.flaws,
							name: ``,
							problem: ``,
							affected: ``,
							table: "tableCode",
							is_pushed: 0
						}));
					}
					setTableOneDataSource(datasource);
				} else if (data[findIndex].content_left === "My List Table") {
					let datasource = [];
					if (data[findIndex].future_action && data[findIndex].future_action.length > 0) {
						const futureActionsArray = data[findIndex].future_action || [];
						datasource = futureActionsArray.map((action, index) => ({
							key: index + 1,
							futureAction: action,
							table: "MyList",
							is_pushed: 0
						}));
					}
					setTableOneDataSource(datasource);
				} else if (data[findIndex].content_left === "Anger Sheet table 3") {
					let datasource = [];
					if (data[findIndex].type_left === "T") {
						datasource = constants.Type1.map((type, index) => ({
							key: index + 1,
							name: type,
							isChecked: ``,
							fearCause: ``,
							fearAction: null,
							futureAction: ``,
							table: "AngerSheetTable3",
							is_pushed: 0,
							item_id: null,
							myreaction:null
						}));
					}
					setTableOneDataSource(datasource);
				}
				else if (data[findIndex].content_left === "Anger Sheet table 4") {
					let datasource = [];
					if (data[findIndex].type_left === "T") {
						datasource = constants.Type2.map((type, index) => ({
							key: index + 1,
							name: type,
							isChecked: ``,
							fearCause: ``,
							fearAction: null,
							futureAction: ``,
							table: "AngerSheetTable4",
							is_pushed: 0,
							item_id: null,
							myreaction:null
						}));
					}
					setTableOneDataSource(datasource);
				}
				else if (data[findIndex].content_left === "Anger Sheet table 5") {
					let datasource = [];
					if (data[findIndex].type_left === "T") {
						datasource = constants.Type3.map((type, index) => ({
							key: index + 1,
							name: type,
							isChecked: ``,
							fearCause: ``,
							fearAction: null,
							futureAction: ``,
							table: "AngerSheetTable4",
							is_pushed: 0,
							item_id: null,
							myreaction:null
						}));
					}
					setTableOneDataSource(datasource);
				}
				else if (data[findIndex].content_left === "Anger Sheet table 6") {
					let datasource = [];
					if (data[findIndex].type_left === "T") {
						datasource = constants.Type4.map((type, index) => ({
							key: index + 1,
							name: type,
							isChecked: ``,
							fearCause: ``,
							fearAction: null,
							futureAction: ``,
							table: "AngerSheetTable4",
							is_pushed: 0,
							item_id: null,
							myreaction:null
						}));
					}
					setTableOneDataSource(datasource);
				}
			} else {
				if (data[findIndex].content_left === "table code") {
					let datasource = [];
					let currentKey = 1;
					if (Array.isArray(data[findIndex].flaws) && data[findIndex].flaws.length > 0) {
						data[findIndex].flaws.forEach((flaw) => {
							const existingRecord = data[findIndex].client_ans.find(
								(ans) => ans.flaws === flaw.flaws && ans.key === flaw.key
							);

							if (existingRecord) {
								datasource.push({
									...existingRecord,
									key: currentKey++,
									flaws: flaw.flaws,
								});
							} else {
								datasource.push({
									key: currentKey++,
									flaws: flaw.flaws,
									name: ``,
									problem: ``,
									affected: ``,
									table: "tableCode",
									is_pushed: 0,
								});
							}
						});
					}
					setTableOneDataSource(datasource);
				} else if (data[findIndex].content_left === "Anger Sheet table") {
					let datasource = [];
					let currentKey = 1;
					if (Array.isArray(data[findIndex].anger) && data[findIndex].anger.length > 0) {
						data[findIndex].anger.forEach((angerItem) => {
							const existingRecord = data[findIndex].client_ans.find(
								(ans) => ans.name === angerItem?.name);
							if (existingRecord) {
								datasource.push({
									...existingRecord,
									key: currentKey++,
									name: angerItem?.name,
								});
							} else {
								datasource.push({
									key: currentKey++,
									name: angerItem?.name,
									problem: ``,
									affected: ``,
									reaction: ``,
									futureAction: ``,
									table: "AngerSheetTable",
									is_pushed: 0,
									item_id:angerItem.item_id
								});
							}
						});
					}
					setTableOneDataSource(datasource);
				} else if (data[findIndex].content_left === "Anger Sheet table 2") {
					let datasource = [];
					let currentKey = 1;
					if (Array.isArray(data[findIndex].fears) && data[findIndex].fears.length > 0) {

						data[findIndex].fears.forEach((fear) => {
							const existingRecord = data[findIndex].client_ans.find((ans) => ans.name === fear.name && ans.key === fear.key);
							if (existingRecord) {
								datasource.push({
									...existingRecord,
									key: currentKey++,
									name: fear.name,
									is_add: fear?.is_add
								});
							} else {
								datasource.push({
									key: currentKey++,
									name: fear.name,
									fearCause: fear.fearCause || ``,
									affected: fear.affected || ``,
									fearAction: fear.fearAction || ``,
									futureAction: fear.futureAction ||``,
									table: "FearTable",
									is_pushed: 0,
									is_add: fear?.is_add,
									is_ticked: fear?.is_ticked,
									item_id:fear.item_id,
									myreaction:fear.myreaction || ``,
								});
							}
						});
					}
					setTableOneDataSource(datasource);
				} else if (data[findIndex].content_left === "My List Table") {
					let datasource = [];
					let currentKey = 1;
					if (data[findIndex].future_action && data[findIndex].future_action.length > 0) {
						const futureActionsArray = data[findIndex].future_action || [];

						futureActionsArray.forEach((action) => {
							const existingRecord = data[findIndex].client_ans.find((ans) => ans.futureAction === action);
							if (existingRecord) {
								datasource.push({
									...existingRecord,
									key: currentKey++
								});
							} else {
								datasource.push({
									key: currentKey++,
									futureAction: action,
									table: "MyList",
									is_pushed: 0
								});
							}
						});
					}
					setTableOneDataSource(datasource);
				} else {
					setTableOneDataSource(data[findIndex].client_ans);
				}
			}
		}
	};

	//setting form field values
	const setFormItemValues = (arrayValue, section_id = null) => {
		for (let index = 0; index < arrayValue.length; index++) {
			const element = arrayValue[index];
			if (section_id !== null) {
				//setting summary value in section 7 and section 1
				//setting form value. Using a index as a key.
				if (section_id === "7" || section_id === "8" || section_id === "100") {
					form.setFieldsValue({ [index]: element.client_ans });
				} else {
					form.setFieldsValue({ [element.item_id]: element.client_ans });
				}
			} else {
				form.setFieldsValue({ [element.item_id]: element.client_ans });
			}
		}
	};

	//rendering templates
	const renderTemplate = (section) => {
		return (
			<>
				{selectTemplate(section)}
			</>
		);
	};

	//handle change for all the common template onchange func.
	const onChangeTemplete = (e, data, type) => {
		if (e) {
			if (clientData.length > 0) {
				let found = false;
				for (let i = 0; i < clientData.length; i++) {
					if (clientData[i].item_id === data.item_id) {
						if (type === "text") {
							clientData[i].textValue = e
						} else {
							clientData[i].option_data = e
						}
						found = true;
						// Removing the object from clientData when e ==="0" & section_id =0. this is for validation purpose
						if (sectionId === "0") {
							if (clientData[i].textValue === "" && type === "text") {
								clientData.splice(i, 1);
							}
						}
						break;
					}
				}
				if (!found) {
					let payload = null;
					payload = {
						item_id: data.item_id,
						section_id: data?.section_id ? data?.section_id : sectionId,
					};
					if (type === "text") {
						payload.textValue = e;
					} else {
						payload.option_data = e;
					}
					if (data?.is_discussed === 1) {
						payload.is_changed = 1;
					}
					clientData.push(payload);
				}
			} else {
				let payload = null;
				payload = {
					item_id: data.item_id,
					section_id: data?.section_id ? data?.section_id : sectionId,
				};
				if (type === "text") {
					payload.textValue = e;
				} else {
					payload.option_data = e;
				}
				if (data?.is_discussed === 1) {
					payload.is_changed = 1;
				}
				clientData.push(payload);
			}
		}
		//gambling validation;
		if (data.item_id === "1048" || data.item_id === "1036") {
			let getIndex = sectionData.findIndex((val) => val.item_id === data.item_id);
			sectionData[getIndex].client_ans = e;
			let result = checkGamblingOption(sectionData);
			setDisplayGamblingValidateMessage(result);
		}
	};

	//onchange function for template with checkbox and textfield
	const onChangeCheckboxValue = (e, data, template_id) => {
		let tempDataCheckbox;
		if (e) {
			tempDataCheckbox = sectionData.filter((item) => (
				item.template_id === template_id
			));
			tempDataCheckbox.map((item, index) => {
				if (item.item_id === data.item_id) {
					if (e.target.checked === false) {
						if (clientData.length > 0) {
							let found = false;
							for (let i = 0; i < clientData.length; i++) {
								if (clientData[i].item_id === data.item_id) {
									clientData.splice(i, 1);
									found = true;
									break;
								}
							}
							if (!found) {
								if (item.client_ans || item.check_box === "t") {
									let payload = null;
									payload = {
										item_id: data.item_id,
										section_id: data?.section_id,
										option_data: 2
									};
									if (data?.is_discussed === 1) {
										payload.is_changed = 1;
									}
									clientData.push(payload);
								}
							}
						} else {
							if (item.client_ans || item.check_box === "t") {
								let payload = null;
								payload = {
									item_id: data.item_id,
									section_id: data?.section_id,
									option_data: 2
								};
								if (data?.is_discussed === 1) {
									payload.is_changed = 1;
								}
								clientData.push(payload);
							}
						}
						let findIndex = sectionData.findIndex((val) => val?.item_id === data?.item_id);
						sectionData[findIndex].client_ans = "";
					} else {
						if (clientData.length > 0) {
							let found2 = false;
							for (let j = 0; j < clientData.length; j++) {
								if (clientData[j].item_id === data.item_id) {
									clientData[j].option_data = 1;
									found2 = true;
									break;
								}
							}
							if (!found2) {
								let payload = null;
								payload = {
									item_id: data.item_id,
									section_id: data?.section_id,
									option_data: 1
								};
								if (data?.is_discussed === 1) {
									payload.is_changed = 1;
								}
								clientData.push(payload);
							}
						} else {
							let payload = null;
							payload = {
								item_id: data.item_id,
								section_id: data?.section_id,
								option_data: 1
							};
							if (data?.is_discussed === 1) {
								payload.is_changed = 1;
							}
							clientData.push(payload);
						}
					}
					tempDataCheckbox[index].check_box = e.target.checked === true ? 't' : 'f';
				}
			});
		}
		setReload(!reload);
	};

	const onCheckboxChange = (e, tempData) => {
		const isChecked = e.target.checked;
		let payload = {
			item_id: tempData?.item_id,
			section_id: sectionId,
			is_checked: isChecked ? "1" : "0"
		};
		clientData.push(payload);

		setReload(!reload);
	}

	const onchangeTextBox = (e, data, template_id) => {
		let tempTextValue;
		tempTextValue = sectionData.filter((item) => (
			item.template_id === template_id
		));
		tempTextValue.map((item, index) => {
			if (item.item_id === data.item_id) {
				tempTextValue[index].client_ans = e.target.value;
				if (clientData.length > 0) {
					let found = false;
					for (let i = 0; i < clientData.length; i++) {
						if (clientData[i].item_id === data.item_id) {
							clientData[i].textValue = e.target.value;
							found = true;
							break;
						}
					}
					if (!found) {
						let payload = null;
						payload = {
							item_id: data.item_id,
							textValue: e.target.value,
							section_id: data?.section_id,
						};
						if (data?.is_discussed === 1) {
							payload.is_changed = 1;
						}
						clientData.push(payload);
					}
				} else {
					let payload = null;
					payload = {
						item_id: data.item_id,
						textValue: e.target.value,
						section_id: data?.section_id,
					};
					if (data?.is_discussed === 1) {
						payload.is_changed = 1;
					}
					clientData.push(payload);
				}
			}
		});
		setReload(!reload);
	};

	//onchange for checkbox table template. 
	const onChangeCheckboxTable = (checkedValue) => {
		setTableCheckedValues(checkedValue);
	};

	//clear signature pad
	const clearSignaturePad = () => {
		sigPad.current.clear();
		setEsignValue("");
	};

	//handle signature pad value
	function handleSignPadVal() {
		// Get the data URL of the signature image
		const dataUrl = sigPad.current.toDataURL();
		setEsignValue(dataUrl);
	};

	//selecting templates based on template id.
	const selectTemplate = (section) => {
		switch (section.template_id) {
			case "0":
				return template0(section.title, section.option_msg, section.scale_msg);

			case "1":
				return template1(section.content_left, section.type_left);

			case "2":
				if (section.item_id === "438") {
					return (
						<>
							{constants.ChangeJarPara}
							<p>
								We have prepared a pdf for you with your flaws and your assets. Please click the links below
								(<a onClick={() => getChangeJar(false)} target="_blank" title="Download this outline to create your own Change list.">Black and White pdf</a>)
								or
								(<a onClick={() => getChangeJar(true)} target="_blank" title="Download this outline to create your own Change list.">Colored pdf</a>) and print it out and fold them and put it in the change jar as discussed with the coach.
							</p>
						</>
					);
				} else {
					// if (section?.item_id === "699") {
					//   let newData = section?.content_left
					//     .replace("parent_name", parentName).replace("child_name", clientName);
					//   menu.push(template2(section?.title, newData, section?.option_msg, section?.scale_msg));
					// }
					return template2(section?.title, section?.content_left, section?.option_msg, section?.scale_msg)
				}

			case "3":
				if (section.template_id === "3" && section?.section_res) {
					let template3List = [];
					for (let index = 0; index < section?.section_res.length; index++) {
						const element = section?.section_res[index];
						const condition = section?.section_res && !(section.section_id === "100" && section.part_id === "4");

						const isCheckedSec_1 = !!element.dt_checked && (section.section_id === element.section_id);
						const isChecked = element?.is_checked;

						//displaying summary data.
						if (element?.client_ans && section.section_id === "1") {
							template3List.push(template3(element?.heading, element, onChangeTemplete, element?.item_id, element, true, null, null, condition, false, onCheckboxChange, isCheckedSec_1));
						}
						//displaying summary and empty text field in section 7 and 8
						if (section.section_id === "7" || section.section_id === "8" || section.section_id === "100") {
							let tooltipValue;
							let disableTextArea;
							if (element?.heading === "" || element?.heading === null) {
								let date = element?.dt_answered ? `(${moment(element?.dt_answered).format(constants.dateformat)})` : ""
								let status = props?.location?.pathname === "/refresher-workbook" ? constants.Status2Ttip : constants.Status3Ttip
								let val = status + " " + date;
								tooltipValue = val;
								disableTextArea = false;
							} else {
								let date = element?.dt_answered ? `(${moment(element?.dt_answered).format(constants.dateformat)})` : ""
								let val = constants.Status1Ttip + " " + date;
								tooltipValue = val;
								disableTextArea = true;
							}
							//For section 8
							if (section.section_id === "8") {
								if ((element?.section_id === "8" && element?.status !== 2) || (element?.section_id === "8" && element?.status === 2 && element?.dt_discussed === null)) {
									disableTextArea = false;
								} else {
									disableTextArea = true;
								}
							}
							// For refresher - section 100
							if (section.section_id === "100") {
								disableTextArea = !(element?.section_id === "100" && !(element?.status === 3 && element?.dt_discussed));
							}
							let date = element?.dt_answered ? `(${moment(element?.dt_answered).format(constants.dateformat)})` : ""
							let labelValue = "Section " + element?.section_id + " " + date;
							template3List.push(template3(element?.heading, element?.type_left, onChangeTemplete, index, element, adminClientValuefromSession?.isAdmin ? true : disableTextArea, tooltipValue, (section.section_id === "7" || section.section_id === "8" || section.section_id === "100") && element.dt_answered ? labelValue : '', condition, false, onCheckboxChange, isChecked));
						}
					}
					return template3List;
				} else {
					return template3(section.content_left, section.type_left, onChangeTemplete, section.item_id, section, adminClientValuefromSession?.isAdmin ? true : section?.is_disable === "t" ? true : false);
				}

			case "4":
				return template4(section.content_left, section.content_right, section.type_left);

			case '5':
				return template5(section.content_left, section, adminClientValuefromSession?.isAdmin ? true : false, onChangeCheckboxValue, onchangeTextBox);

			case '6':
				return template6(section?.content_left, section?.title, section?.video_url);

			case '7':
				return template7(section.content_left, section, adminClientValuefromSession?.isAdmin ? true : false, onChangeTemplete)

			case '8':
				return template8(section.content_left, section, adminClientValuefromSession?.isAdmin ? true : false, onChangeTemplete);

			case '9':
				return template9(section?.stress_chart_result);

			case '10':
				return template10(section.content_left);

			case '11':
				return template11(section?.content_left, section?.content_right, section?.type_left, section?.type_right);

			case '12':
				return template12(section.content_left);

			case '13':
				return template13(section.content_left, section, adminClientValuefromSession?.isAdmin ? true : section.is_disable === "t" ? true : false, onChangeTemplete);

			// case '14':
			//   return template14(section.content_left);

			case '15': {
				const condition = section?.content_left === "Anger Sheet table";
				return template15(section, adminClientValuefromSession?.isAdmin, tableOneColumns, tableComponents, tableOneDataSource, handleChange, handleAdd, handleActionSave, handleFutureChangeAndMyreaction, handleDelete, condition ? newSec4Option : newSec5Option, FutureInputRef, newOption, handleNewOptionChange, handleAddNewOption, handleAddOnSpecificIndex, handleFearChange, handleCheckboxChange, handleFearInputChange);
			}

			// case '16':
			//   return template16(section.content_left, section.type_left);

			// case '17':
			//   return template17(section.content_left, section.content_right, section.type_left);

			case '18':
				return template18(section, adminClientValuefromSession?.isAdmin ? true : false, onChangeCheckboxTable, tableCheckedValues);

			case '19':
				return template19(section.content_left, section, adminClientValuefromSession?.isAdmin ? true : false, onChangeCheckboxValue, onchangeTextBox);

			case '20':
				return template20(sigPad, clearSignaturePad, handleSignPadVal)

			case '21':
				return luscherColorTest(boxes, handleBoxClick, section?.item_id, section?.client_ans?.length > 0 ? "test taken" : null, loginType === "1" ? adminClientValuefromSession?.client_name : null, resetLusherColor, downloadResult)

			case '22':
				return uploadFile(fileList, handleFileChange, handleRemove, beforeUpload, "age-proof")

			case '23':
				return template23(section?.content_left, section?.client_ans)

			case '24':
				return template24(section.content_left, section, adminClientValuefromSession?.isAdmin ? true : section.is_disable === "t" ? true : false, onChangeTemplete)

			case '26':
				return template26(section.content_left, section, adminClientValuefromSession?.isAdmin ? true : section.is_disable === "t" ? true : false, onChangeTemplete)

			case '31':
				return templateVideoStreaming(`${constants.ApiUrl}/get-video/${section?.content_left}/index.m3u8`);

			case '32':
				return templateYouTube(section?.content_left);

			default:
				return null;
		}
	};

	const handleGamblingAddiction = () => {
		let findIndex = sectionData.findIndex((val) => val.item_id === '1036');
		if (findIndex > -1) {
			//Gambling validation.
			let checkGamblingOptionAns = checkGamblingOption(sectionData);
			if (checkGamblingOptionAns) {
				inCreamentCounter(counter, 3);
				sessionStorage.setItem('is_gambling_addiction', 3);
			} else {
				sessionStorage.setItem('is_gambling_addiction', 1);
				inCreamentCounter(counter, 1);
			}
		} else {
			sessionStorage.removeItem('is_gambling_addiction', 1);
			inCreamentCounter(counter, 1);
		}
	}

	//increamenting counter value 
	const inCreamentCounter = (count, length) => {
		setCounter(count + length);
		sessionStorage.setItem('count', count + length);
	};

	//decreamenting counter value 
	const decreamentCounter = (count, length) => {
		setCounter(count - length);
		sessionStorage.setItem('count', count - length);
	};

	//function onclick next;
	const handleNextPart = () => {
		if (loginType === "0") {
			handleEditableTableClientData();
			handleCheckboxTableClientData();
			handleValidate("Next");
		} else {
			if (clientData.length > 0) {
				addAnswers(clientData)
			}
			handleGamblingAddiction();
		}
		document.getElementById('top').scrollIntoView({ behavior: "smooth" });
	};

	//function to validate form fields.
	const handleValidate = async (type, payload = null) => {
		try {
			const values = await form.validateFields();
			if (type === "Submit") {
				if (clientData.length > 0) {
					addAnswers(clientData)
						.then((res) => {
							// Handle success
							if (res?.code === 200) {
								updateSection(clientId, "0", "2", sectionId);
								if (enableTill !== sectionLength) {
									callSaveandCloseApi(clientId, payload, null);
								}
							} else {
								message.error(res?.message);
							}
						})
						.catch((error) => {
							console.error('Error:', error);
							// Handle error
							message.error(error)
						});
				} else {
					const findIndex = sectionData.findIndex((val) => val?.template_id === "7");
					if (findIndex > -1 && sectionData[findIndex].client_ans === null) {
						message.error(constants.ValidationMessage);
					} else {
						updateSection(clientId, "0", "2", sectionId);
						if (enableTill !== sectionLength) {
							callSaveandCloseApi(clientId, payload, null);
						}
					}
				}

			}
			else if (type === "SubmitSectionZero") {
				console.log("here", esignValue);
			}
			else {
				if (clientData.length > 0) {
					// api call add answers
					addAnswers();
				} else {
					//lushercolor validation
					const findIndex = sectionData.findIndex((val) => val?.template_id === "21");
					if (findIndex > -1 && sectionData[findIndex].client_ans === null) {
						message.error("Please Answer the Lusher color test.");
					} else {
						handleGamblingAddiction();
					}
				}
			}
			// You can call onFinish or submit the form if validation succeeds
		} catch (errorInfo) {
			console.log('Validation failed:', errorInfo);
			// Handle validation errors or display error messages
			message.error('Please fill in all required fields.');
		}
	};
	//calling add answer api
	const addAnswers = (type = null, showmessage = null) => {
		return new Promise((resolve, reject) => {
			let payload = {
				client_id: clientId,
				client_data: type !== null ? type : clientData,
			};
			fetchApi(`/add-answers`, "post", payload)
				.then((res) => {
					if (res?.code === 200) {
						if (type === null) {
							//gambling validation
							let findIndex = sectionData.findIndex((val) => val.item_id === '1036');
							if (findIndex > -1) {
								let checkGamblingOptionAns = checkGamblingOption(clientData);
								if (checkGamblingOptionAns) {
									inCreamentCounter(counter, 3);
									sessionStorage.setItem('is_gambling_addiction', 3);
								} else {
									sessionStorage.setItem('is_gambling_addiction', 1);
									inCreamentCounter(counter, 1);
								}
							} else {
								sessionStorage.removeItem('is_gambling_addiction', 1);
								inCreamentCounter(counter, 1);
							}
						}
						if (showmessage !== null) message.success(res?.message);
						setClientData([]);
					} else {
						message.error(res?.message);
					}
					resolve(res); // Resolve with the status code
				})
				.catch((error) => {
					reject(error); // Reject with the error if fetchApi fails
				});
		});
	};

	//modal related functions
	const getModal = () => {
		//popup for esign message
		if (sectionId === "0") {
			return popupMessage(
				null,
				// esignMessageModalOpen,
				// handleEsignMessageModalOk,
				// counter === constants.SectionZeroPart.coachingAgreementpagetwo ? constants.EsignPopupMessageConsentForm : constants.EsignPopupMessage,
				null,
				null,
				null
			);
		} else {
			return popupMessage(
				constants.WorkbookHeaderText,
				instructionModalOpen,
				handleInstructionmodalOk,
				/* token === "refresher-workbook" ? constants.WorkbookInstructionsTextRefresher :  */constants.WorkbookInstructionsText,
				/* token === "refresher-workbook" ? null : */ `${sectionId}.`,
				constants.WorkbookInstructionsText2,
				constants.complete4
			);
		}
	};

	//function onclick previous;
	const handlePreviousPart = () => {
		let checkGamblingAddiction = sessionStorage.getItem('is_gambling_addiction');
		if (checkGamblingAddiction) {
			decreamentCounter(counter, checkGamblingAddiction)
		} else {
			decreamentCounter(counter, 1)
		}
	};

	//Instruction modal
	const showInstructionModal = () => {
		setInstructionModalOpen(true);
	};
	const handleInstructionmodalOk = () => {
		setInstructionModalOpen(false);
	};

	//handle Submit
	const handleSubmit = () => {
		if (loginType === "0") {
			let save_and_close_payload = {
				section_id: props?.location?.pathname === "/refresher-workbook" ? "100" : sectionId,
				is_admin: loginType,
				part_id: counter
			}
			// if(clientData.length>0){
			// 	handleValidate("Submit",save_and_close_payload);
			// }else{
			// 	updateSection(clientId,"0","2",sectionId);
			// 	if(enableTill !== sectionLength){
			// 		callSaveandCloseApi(clientId,save_and_close_payload,null);
			// 	}
			// }
			if (parseInt(sectionId) === 0) {
				handleValidate("SubmitSectionZero");
			} else {
				handleEditableTableClientData();
				handleCheckboxTableClientData();
				handleValidate("Submit", save_and_close_payload);
			}
		} else {
			getDiscussionValue();
			if (clientData.length) {
				addAnswers(clientData);
			}
			updateSection(clientId, "1", sectionLength === enableTill ? "2" : "5", sectionId);
		}
	};

	const updateSection = (client_id, is_admin, flag, section_id) => {
		setLoader(true);
		let payload = {
			is_admin: is_admin,
			flag: flag,
			section_id: section_id
		}
		fetchAdminApi(`/update-client-sections/${client_id}`, "put", payload).then((res) => {
			if (res?.code === 200) {
				setLoader(false);
				if (is_admin === "1") {
					if (enableTill === sectionLength) {
						history.push("/dashboard");
					} else {
						fetchWorkbookSections(sectionId, counter);
					}
				} else {
					let prop_state = null;
					if (enableTill === sectionLength) {
						prop_state = {
							isPopUpMessage: true,
							sectionId: sectionId
						};
					}
					history.push({
						pathname: "/dashboard",
						state: prop_state
					});
				}
				message.success(res?.message);
			} else {
				message.error(res.message);
				setLoader(false);
			}
		})
	};

	const getDiscussionValue = () => {
		if (props?.location?.state?.status !== "3" || adminClientValuefromSession?.status !== "3") {
			let noClientAns = false;
			sectionData?.filter((item) => {
				if (item.client_ans !== null) {
					return clientData.push({
						section_id: item.section_id,
						item_id: item.item_id,
						is_admin: "1",
						part_id: counter
					});
				} else {
					noClientAns = true;
				}
			});
			if (noClientAns) {
				if (lastDiscussedPartId) {
					if (parseInt(lastDiscussedPartId) < counter) {
						clientData.push({
							section_id: props?.location?.pathname === "/refresher-workbook" ? "100" : sectionId,
							is_admin: "1",
							part_id: counter
						});
					}
				} else {
					clientData.push({
						section_id: props?.location?.pathname === "/refresher-workbook" ? "100" : sectionId,
						is_admin: "1",
						part_id: counter
					});
				}
			}
			if (sectionId === 8) {
				sectionData?.forEach((item) => {

					// Check nested items in section_res if they exist
					if (item.section_res) {
						item.section_res.forEach((nestedItem) => {
							if (nestedItem.section_id === "8" && nestedItem.client_ans && nestedItem.dt_discussed === null) {
								clientData.push({
									item_id: nestedItem.item_id,
									section_id: nestedItem.section_id,
									is_admin: "1",
									part_id: counter,
								});
							}
						});
					}
				});
			}
		}
	};

	const handleAdminDiscussed = () => {
		getDiscussionValue();
		if (clientData.length > 0) {
			addAnswers();
		} else {
			inCreamentCounter(counter, 1);
		}
		// if(displayGamblingValidateMessage){
		// 	setCounter(counter + 3);
		// 	sessionStorage.setItem('count', counter + 3);
		// }else{
		// 	setCounter(counter + 1);
		// 	sessionStorage.setItem('count', counter + 1);
		// }
		document.getElementById('top').scrollIntoView({ behavior: "smooth" });
	};

	//function to save and close
	const handleSaveAndClose = () => {
		let client_id = null;
		let state = null;
		if (adminClientValuefromSession?.clientId) {
			client_id = adminClientValuefromSession?.clientId;
			state = {
				isAdmin: true,
				clientId: adminClientValuefromSession?.clientId,
				client_name: adminClientValuefromSession?.client_name,
				client_serial_number: adminClientValuefromSession?.client_serial_number
			};
		} else {
			client_id = getCookie(COOKIE.ClientId);
		}
		let payload = {
			section_id: props?.location?.pathname === "/refresher-workbook" ? "100" : sectionId,
			is_admin: loginType,
			part_id: counter
		};
		if (loginType === "0") {
			handleEditableTableClientData();
			handleCheckboxTableClientData();
			if (clientData.length > 0) {
				addAnswers(clientData)
					.then((res) => {
						// Handle success
						if (res?.code === 200) {
							callSaveandCloseApi(client_id, payload, state);
						} else {
							message.error(res?.message);
						}
					})
					.catch((error) => {
						console.error('Error:', error);
						// Handle error
						message.error(error)
					});
			} else {
				callSaveandCloseApi(client_id, payload, state);
			}
		} else {
			if (clientData.length > 0) {
				addAnswers(clientData)
					.then((res) => {
						// Handle success
						if (res?.code === 200) {
							callSaveandCloseApi(client_id, payload, state);
						} else {
							message.error(res?.message);
						}
					})
					.catch((error) => {
						console.error('Error:', error);
						// Handle error
						message.error(error)
					});
			} else {
				callSaveandCloseApi(client_id, payload, state);
			}
			if (noteValue) {
				message.error(constants.notesVM);
			} else {
				callSaveandCloseApi(client_id, payload, state);
			}
		}
	};

	const callSaveandCloseApi = (client_id, payload, state) => {
		setLoader(true);
		fetchApi(`/save-and-close/${client_id}`, "put", payload).then((res) => {
			if (res && res.code === 200) {
				setLoader(false);
				history.push({
					pathname: "/dashboard",
					state: state
				});
			} else {
				setLoader(false);
				message.error(res.message);
			}
		});
	};

	const displayResumeButton = () => {
		if (loginType === "0") {
			if (isResume?.client_resume && counter === 1) {
				return <Button className='bg-sky-800 text-white uppercase' onClick={handleResume}>{constants.Resume}</Button>;
			}
		} else {
			if (isResume?.admin_resume && counter === 1) {
				return <Button className='bg-sky-800 text-white uppercase' onClick={handleResume}>{constants.Resume}</Button>;
			}
		}
	};

	const handleResume = () => {
		if (loginType === "0") {
			if (isResume.client_resume !== null) {
				inCreamentCounter(0, parseInt(isResume.client_resume));
			}
		} else {
			if (isResume.admin_resume !== null) {
				inCreamentCounter(0, parseInt(isResume.admin_resume));
			}
		}
	};

	const handleClose = () => {
		let noteValue = null //check when you integrate add notes 
		if (noteValue) {
			message.error(constants.notesVM);
		} else {
			history.push({ pathname: "/dashboard" });
		}
	};

	const nextBtn = ({ additionalClass = '', size = '' }) => (
		(counter < enableTill && !displayGamblingValidateMessage) || (loginType !== "0" && counter < sectionLength) ?
			<Button className={`bg-sky-800 text-white uppercase ${additionalClass}`} size={size} disabled={loader} onClick={handleNextPart}>{constants.Next}</Button> :
			null
	);

	const previousBtn = ({ additionalClass = '', size = '' }) => (
		counter >= 2 ?
			<Button className={`bg-sky-800 text-white uppercase ${additionalClass}`} size={size} disabled={loader} onClick={handlePreviousPart}>{constants.Previous}</Button> :
			null
	);

	const routeToListNotes = () => {
		window.open(constants.BaseUrl + "/notes");
	};

	//notes functions start here
	const getForm = (formType) => {
		setNoteTypeValue(formType);
		setLoader(true);
		let payload = {
			note_type: formType,
			client_id: adminClientValuefromSession?.clientId,
		};
		fetchAdminApi("/notes", 'post', payload).then((res) => {
			if (res?.code === 200) {
				setLoader(false);
				setNoteValue(res?.data?.data);
				if (res?.data?.is_note_answered === 1) {
					setIsNoteAnswered(true);
				} else {
					setIsNoteAnswered(false);
				}
				// setNoteTitle(res?.data?.date);
			} else {
				setNoteValue([]);
				// setNoteTitle("");
				setLoader(false);
			}
		});
	};

	const addNotes = () => {
		document.getElementById('top').scrollIntoView({ behavior: "smooth" });
		getForm(noteTypeValue);
		setnotesVisible(true);
	};

	const hideModal = () => {
		setNoteValue(null);
		setNoteTypeValue("0");
		setnotesVisible(false);
	};

	const onchangeNoteValue = (e, index) => {
		noteValue[index] = e;
	};

	const createNote = (type) => {
		let admin_id = getCookie(COOKIE.ClientId);
		let payload = {
			client_id: adminClientValuefromSession?.clientId,
			notes_content: noteValue[0] ? "<b><u>DATA:</u></b>" + " " + noteValue[0] : "",
			notes_assesment: noteValue[1] ? "<b><u>ASSESSMENT:</u></b>" + " " + noteValue[1] : "",
			notes_plan: noteValue[2] ? "<b><u>PLAN:</u></b>" + " " + noteValue[2] : "",
			section_id: props?.location?.pathname === "/refresher-workbook" ? "100" : sectionId,
			admin_id: admin_id,
			notes_type: noteTypeValue,
			dt_created: `${noteDate.year}-${noteDate.month}-${noteDate?.date}`
		};
		if (sessionChecked === true) {
			payload.session_checked = sessionChecked;
		}
		if (type === "esign") {
			payload.signed_by = admin_id;
		}
		if (noteValue !== null) {
			fetchAdminApi(`/add-notes`, "post", payload).then((res) => {
				if (res && res.code && res.code === 200) {
					hideModal();
					setNoteDate({
						...noteDate,
						date: current.getDate(),
						month: String(current.getMonth() + 1),
						year: current.getFullYear()
					});
					message.success(res.message);
				} else {
					message.error(res.message);
				}
			});
		}
	};
	//date of notes date onchange
	const handleNoteDayChange = (value) => {
		setNoteDate({
			...noteDate,
			date: value
		});
	};

	const handleNoteMonthChange = (value) => {
		setNoteDate({
			...noteDate,
			month: value
		});
	};

	const handleNoteYearChange = (value) => {
		setNoteDate({
			...noteDate,
			year: value
		});
	};
	//notes functions ends here.

	//redirect to editclient page.
	const redirectToEditProfile = () => {
		window.open(constants.BaseUrl + "/edit-client");
	};

	const getMedicalFormData = () => {
		sessionStorage.clear();
		let payload = {
			clientId: getCookie(COOKIE.ClientId),
			client_name: getCookie(COOKIE.FirstName) + " " + getCookie(COOKIE.LastName),
			is_medical_info: true,
			count: counter
		};
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/medical-info");
	};

	const getChangeJar = (isColored) => {
		let payload = {
			color: isColored,
		};
		sessionStorage.setItem("colorValue", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/change-jar");
	}

	// Filter `option.label` match the user type `input`
	const selectFilterOption = (input, option) => {
		return option?.children?.props?.children[2]?.props?.title?.toLowerCase().indexOf(input?.toLowerCase()) > -1 && option?.children?.props?.children[2]?.props?.title?.toLowerCase().indexOf(input?.toLowerCase()) < 1;
	};

	const handleGoToPage = (page_no) => {
		if (page_no !== undefined) {
			let findIndex = goToPageData.findIndex((val) => val.page_no === page_no);
			if (loginType !== "0") {
				inCreamentCounter(0, parseInt(page_no))
			} else {
				if (goToPageData[findIndex].is_enabled) {
					inCreamentCounter(0, parseInt(page_no));
				}
			}
		}
	};

	const checkGamblingOption = (userData) => {
		let option1 = userData?.findIndex((item) => item?.item_id === "1036");//item id of option1 of gambling
		let option2 = userData?.findIndex((item) => item?.item_id === "1048");//item id of option2 of gambling
		let result;
		if (userData?.[option1]?.option_data === "2" && userData?.[option2]?.option_data === "2" || userData?.[option1]?.client_ans === "2" && userData?.[option2]?.client_ans === "2") {
			result = true;
		} else {
			result = false;
		}
		return result;
	};

	function capitalizeFirstLetter(str) {
		return str?.toLowerCase()?.replace(/(?:^|\s)\S/g, function (a) {
			return a?.toUpperCase();
		});
	};

	const callStoreSignApi = (payload, type) => {
		fetchApi(`/store-signature`, "post", payload).then((res) => {

		});
	};

	const onSessionChange = (value) => {
		setSessionChecked(value);
	}

	return (
		<Spin spinning={loader} size="large">
			<div className='mb-5'>
				<div className='bg-amber-300 text-lg font-semibold p-3 flex flex-wrap items-center justify-center'>
					{constants.Subtitle}
					<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{parseInt(sectionId) === 0 ? (<span>{secZeroSubTitle?.[counter - 1]?.page_title}</span>) : (<>{parseInt(sectionId) === 100 ? (<><span>{constants.RefresherWorkSheet}</span><span className="ml-3">Page <span className='text-lg'>#{counter}</span></span></>) : (<><span>{constants.sec} <span className='text-lg'>#{sectionId}</span></span><span className="ml-3">Page <span className='text-lg'>#{counter}</span></span></>)}</>)}</span>
				</div>
				{
					loginType !== "0" ? (
						<div className='bg-amber-100 text-base font-semibold p-2 mb-2'>
							<div className="container mx-auto flex justify-between items-center">
								<Space size={'small'} wrap direction="vertical">
									<span onClick={() => {
										history.push({
											pathname: "/edit-client",
											state: { client_id: adminClientValuefromSession?.clientId }
										});
									}} className="font-semibold text-base text-black cursor-pointer">{constants.AdminWorkbook} - {`${adminClientValuefromSession?.client_name ? adminClientValuefromSession?.client_name + " " : ""} (#${adminClientValuefromSession?.client_serial_number ? adminClientValuefromSession?.client_serial_number : ""})`}</span>
									<Space wrap>
										<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => history.goBack()}>{constants.BackBtn}</Button>
										<Space className='mx-4'>
											{
												previousBtn({ additionalClass: 'text-xs font-semibold', size: 'small' })
											}
											{
												nextBtn({ additionalClass: 'text-xs font-semibold', size: 'small' })
											}
										</Space>
										<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={routeToListNotes}>{constants.ViewNotes}</Button>
										{
											sectionId !== 0 ?
												<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={addNotes}>{constants.AddNote}</Button>
												: null
										}
										{
											sectionId !== "0" ?
												<>
													<Space className='mx-3'>
														<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={redirectToEditProfile}>Profile</Button>
													</Space>
												</> :
												null
										}
										{/* go to page dropdown condition */}
										{
											sectionId > 0 ?
												<Select
													placeholder={constants.GoToPage}
													className='!w-[350px]'
													showSearch
													allowClear
													filterOption={selectFilterOption}
													onChange={handleGoToPage}
												>
													{goToPageData &&
														goToPageData.map((goToPageData) => {
															return <Select.Option key={goToPageData.page_no} value={goToPageData.page_no}>
																<Space>
																	{goToPageData?.is_edited ? <EditOutlined className='min-w-[15px]' /> : <div className='w-[15px]'></div>}
																	<span className='w-6 text-right block m-auto'>{goToPageData.page_no} : </span>
																	<p className="text-ellipsis overflow-hidden max-w-[240px]" title={capitalizeFirstLetter(`${goToPageData.page_title}`)}>{capitalizeFirstLetter(`${goToPageData.page_title}`)}</p>
																	{goToPageData?.is_enabled ? <UnlockOutlined className='min-w-[15px]' /> : <LockOutlined className='min-w-[15px]' />}
																</Space>
															</Select.Option>
														})}
												</Select> :
												null
										}
									</Space>
								</Space>
							</div>
						</div>
					) :
						<div className=' bg-amber-100 p-2 mb-2'>
							<div className='container mx-auto '>
								<Space className={newUser && 'flex justify-between'}>
									{
										previousBtn({ additionalClass: 'text-xs font-semibold', size: 'small' })
									}
									{
										nextBtn({ additionalClass: 'text-xs font-semibold', size: 'small' })
									}
									{
										sectionId > 0 ?
											<Select
												placeholder={constants.GoToPage}
												className='!w-[350px]'
												showSearch
												allowClear
												filterOption={selectFilterOption}
												onChange={handleGoToPage}
											>
												{goToPageData &&
													goToPageData.map((goToPageData) => {
														return <Select.Option key={goToPageData.page_no} value={goToPageData.page_no}>
															<Space>
																{goToPageData?.is_edited ? <EditOutlined className='min-w-[15px]' /> : <div className='w-[15px]'></div>}
																<span className='w-6 text-right block m-auto'>{goToPageData.page_no} : </span>
																<p className="text-ellipsis overflow-hidden max-w-[240px]" title={capitalizeFirstLetter(`${goToPageData.page_title}`)}>{capitalizeFirstLetter(`${goToPageData.page_title}`)}</p>
																{goToPageData?.is_enabled ? <UnlockOutlined className='min-w-[15px]' /> : <LockOutlined className='min-w-[15px]' />}
															</Space>
														</Select.Option>
													})}
											</Select> :
											null
									}
									{newUser && (<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => { history.push({ pathname: "/my-profile", state: { counter: counter } }); }}>{constants.MyProfileText}</Button>)}
									{sectionId === 0 && <Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={getMedicalFormData}>{constants.ReleaseForm}</Button>}
								</Space>
							</div>
						</div>
				}
				{
					sectionData?.length > 0 ? (
						<div className='container mx-auto mt-2 px-2'>
							<Form layout='vertical' form={form}>
								<>
									{
										sectionData?.map((section) => {
											return <span key={section.item_id} className='disabled-dark font-large'>{renderTemplate(section)}</span>
										})
									}
									{enableTill === counter && loginType === "0" ? <Alert className='font-bold text-red-500 text-base w-fit py-1.5 mt-2 mb-4' message={constants.SectionApprovalMessage} type='error' /> : null}
									<Row>
										<Col span={24}>
											<Space size={'small'} wrap>
												{/* tick icon if admin has dissed the page */}
												{
													lastDiscussedPartId && counter <= parseInt(lastDiscussedPartId) ?
														(<CheckOutlined />) :
														null
												}
												{
													loginType !== "0" && counter < enableTill ?
														(<Button className='bg-amber-300 text-black uppercase' disabled={loader ? true : false} onClick={handleAdminDiscussed}>{constants.Discussed}</Button>) :
														null
												}
												{
													enableTill === counter && loginType !== "0" ?
														<Button className={'bg-amber-300 black-white uppercase'} disabled={loader ? true : false} onClick={handleSubmit}>{constants.Completed}</Button> :
														null
												}
												{
													previousBtn({})
												}
												{
													nextBtn({})
												}
												{
													enableTill === counter && loginType === "0" || displayGamblingValidateMessage ?
														<Button className={'bg-sky-800 text-white uppercase'} disabled={loader ? true : false} onClick={handleSubmit}>{constants.Submit}</Button> :
														null
												}
												{
													//removed save and close for medical info release form
													counter !== enableTill && sectionId > 0 && !displayGamblingValidateMessage/* && !is_parent_concent */ ?
														(<Button className='bg-sky-800 text-white uppercase' disabled={loader ? true : false} onClick={handleSaveAndClose}>{constants.SaveClose}</Button>)
														: null
												}
												{/* resume button condition for admin and client side */}
												{
													sectionId === "0" ? null : displayResumeButton()
												}
												{/* close button for admin */}
												{
													loginType !== "0" && counter === sectionLength ?
														(<Button className='bg-sky-800 text-white uppercase' onClick={handleClose}>{constants.CloseBtn}</Button>) :
														null
												}
											</Space>
											{/* To display validate message for gambling section page. */}
											{displayGamblingValidateMessage ? (
												<div className="font-semibold text-lg">{constants.GamblingValidateMessage}</div>
											) : null}
										</Col>
									</Row>
								</>
							</Form>
							<div style={{ visibility: "hidden" }}>
								<b>partId:</b> {counter}
							</div>
						</div>
					) :
						null
				}
				{getModal()}
				{noteValue && notesModel(hideModal, notesVisible, onchangeNoteValue, createNote, getForm, noteValue, noteTypeValue, isNoteAnswered, DOB(noteDate.month, handleNoteMonthChange, noteDate.date, handleNoteDayChange, noteDate.year, handleNoteYearChange, "Date", false), onSessionChange, sessionChecked, false, null, null)}
			</div>
		</Spin>
	)
}

export default WorkbookSections;